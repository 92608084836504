export const reviews = {
  totalReviews: 112,
  moyenReviews: 3.7,
  reviewsData: [
    {
      id: 1,
      imageUri: require("../assets/user1.png"),
      username: "User Name1",
      totalRate: "5.0",
      content:
        "This is a review This is a review This is a review This is a review This is a review This is a review This is a review ",
    },
    {
      id: 2,
      imageUri: require("../assets/user2.png"),
      username: "User Name2",
      totalRate: "5.0",
      content:
        "This is a review This is a review This is a review This is a review This is a review This is a review This is a review ",
    },
    {
      id: 3,
      imageUri: require("../assets/user3.png"),
      username: "User Name3",
      totalRate: "5.0",
      content:
        "This is a review This is a review This is a review This is a review This is a review This is a review This is a review ",
    },
  ],
};
