import { Title } from "..";

const About = () => {
  return (
    <div className="about">
      <Title text="About Me" className="about__title" />
      <p className="about__desc">
        This is a bit more about my knowledge and experience This is a bit more
        about my knowledge and experience This is a bit more about my knowledge
        and experience This is a bit more about my knowledge and experience{" "}
      </p>
    </div>
  );
};

export default About;
