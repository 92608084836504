import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/logo.png";
import user from "../../assets/avatar.png";

import { NavItem } from "..";
import { hideOverflowing } from "../../utils/header";

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    hideOverflowing(navOpen);
  }, [navOpen]);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__logo">
            <Link
              to="/"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={logo} alt="zoom me logo" />
            </Link>
          </div>
          <nav className={`header__nav ${navOpen ? "header__nav-hidden" : ""}`}>
            <ul className="header__nav__list">
              <NavItem title="All Questions" to="/" />
              <NavItem title="Communities" to="/communities" />
              <NavItem title="My Questions" to="/my-questions" />
              <NavItem title="Ask" to="/ask" />
            </ul>
          </nav>
        </div>
        <div className="header__user">
          <img src={user} alt="user" className="header__user-img" />
        </div>
        <label
          className={`header__menu ${navOpen ? "open" : ""}`}
          onClick={() => setNavOpen((prev) => !prev)}
        >
          <span></span>
        </label>
        <div
          className="overlay"
          style={{
            transform: navOpen ? "translateX(0)" : "translateX(100%)",
          }}
        >
          <nav className="header__nav">
            <ul className="header__nav__list">
              <NavItem
                title="All Questions"
                to="/"
                onClick={() => setNavOpen(false)}
              />
              <NavItem
                title="Communities"
                to="/communities"
                onClick={() => setNavOpen(false)}
              />
              <NavItem
                title="My Questions"
                to="/my-questions"
                onClick={() => setNavOpen(false)}
              />
              <NavItem
                title="Ask"
                to="/ask"
                onClick={() => setNavOpen(false)}
              />
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
