const Title = ({ text, className = undefined }) => {
  return (
    <div className={`title ${className}`}>
      <h2 className="title__text">{text}</h2>
      <div className="title__underline" />
    </div>
  );
};

export default Title;
