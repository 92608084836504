import { About, Reviews } from "..";

const RightSection = () => {
  return (
    <div className="right-section">
      <About />
      <Reviews />
    </div>
  );
};

export default RightSection;
