import "./main.scss";
import { Header, LeftSection, RightSection } from "./components";

const App = () => {
  return (
    <>
      <Header />
      <main className="main">
        <LeftSection />
        <RightSection />
      </main>
    </>
  );
};

export default App;
