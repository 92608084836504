import { NavLink } from "react-router-dom";

const NavItem = ({ title = "", to = "/", onClick }) => {
  return (
    <li className="header__nav__list-item" onClick={onClick}>
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? "active" : undefined)}
      >
        {title}
      </NavLink>
    </li>
  );
};

export default NavItem;
