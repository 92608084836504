import { Title } from "../";
import user from "../../assets/owner.png";

const LeftSection = () => {
  return (
    <div className="left-section">
      <Title text="Details" className="left-section__title" />
      <img src={user} alt="user" className="left-section__img" />
      <div className="left-section__info">
        <h3 className="left-section__info-username">User Name</h3>
        <p className="left-section__info-desc">User short description</p>
      </div>
      <div className="left-section__tags">
        <div>SQL</div>
        <div>SQL</div>
        <div>SQL</div>
      </div>
      <button className="left-section__btn">Edit Profile</button>
      <h4 className="left-section__community">My Communities:</h4>
      <p className="left-section__speciality">Junior devs, Startup founders</p>
    </div>
  );
};

export default LeftSection;
