import { Stars } from "..";

const Card = ({ review }) => {
  return (
    <div className="reviews__container-card">
      <div className="reviews__container-card__img">
        <img src={review.imageUri} alt="" />
      </div>
      <div className="reviews__container-card-detail">
        <div className="reviews__container-card-detail__info">
          <h4 className="reviews__container-card-detail__info-username">
            {review.username}
          </h4>
          <div className="reviews__container-card-detail__info-rate">
            <Stars width={100} numStars={1} />
            {review.totalRate}
          </div>
        </div>
        <p className="reviews__container-card-detail__desc">{review.content}</p>
      </div>
    </div>
  );
};

export default Card;
