import { Card, Title, Stars } from "..";
import { reviews } from "../../data/reviews";

const Reviews = () => {
  return (
    <div className="reviews">
      <Title text="Reviews" className="reviews__title" />
      <div className="container">
        <div className="reviews__total">
          <p className="reviews__total-number">{reviews.moyenReviews}</p>

          <Stars width={(reviews.moyenReviews * 100) / 5} numStars={5} />
          <p className="reviews__total-reviews">
            {reviews.totalReviews} Reviews
          </p>
        </div>
        <div className="reviews__container">
          {reviews.reviewsData.map((review) => (
            <Card key={review.id} review={review} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
